<template>
  <div class="ma-0 pa-0">
    <v-container v-if=" page !== undefined && page.page !== undefined">
       <v-row>
        <h1 style="margin-right: 20px">Gedenkseiten</h1>
      </v-row>
      <router-link :to="{ name: 'CRMPageUserCollection' }">Zurück</router-link>
      <v-row class="pa-0 ma-2">
        <h2>User</h2>
      </v-row>
      <v-row>
        <v-col class="ma-2">
          <!-- Mail -->
          <v-row>
            <div>Mail:</div>
            <v-spacer></v-spacer>
            <div v-if=" page.user !== null && page.user.email !== undefined">
              {{ page.user.email }}
            </div>
          </v-row>
          <!-- Name -->
          <v-row>
            <div>Vornamen:</div>
            <v-spacer></v-spacer>
            <div v-if="page.user">
              {{ page.user.prename }}
            </div>
          </v-row>
          <!-- Family Name -->
          <v-row>
            <div>Nachname:</div>
            <v-spacer></v-spacer>
            <div v-if="page.user">
              {{ page.user.surname }}
            </div>
          </v-row>

          <!-- Birthday -->
          <v-row>
            <div>Geburtstag:</div>
            <v-spacer></v-spacer>
            <div v-if="page.user && page.user.birth" >
              {{ new Date(page.user.birth).toLocaleDateString() }}
            </div>
          </v-row>
        </v-col>
        <v-col class="ma-2">
          <!-- Created -->
          <v-row>
            <div>Erstellt am:</div>
            <v-spacer></v-spacer>
            <div v-if="page.page.ct !== undefined">
              {{new Date(page.page.ct).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'})}}
            </div>
          </v-row>

          <!-- last Login -->
          <v-row>
            <div>Letzter Login:</div>
            <v-spacer></v-spacer>
            <div v-if="page.page.ut !== undefined">
              {{new Date(page.page.ut).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'})}}
            </div>
          </v-row>

          <!-- Verified -->
          <v-row>
            <div>Verifiziert:</div>
            <v-spacer></v-spacer>
            <div v-if="page.page.verified !== undefined && page.page.verified.status !== undefined">
              {{ mapVerifiedStatus() }}
            </div>
          </v-row>

          <!-- Partner -->
          <v-row>
            <div>Partner:</div>
            <v-spacer></v-spacer>
            <div v-if="page.user && page.user.isP">
              {{ page.user.isP }}
            </div>
          </v-row>

          <!-- Newslatter -->
          <v-row>
            <div>Newslatter:</div>
            <v-spacer></v-spacer>
            <div v-if="page.user && page.user.notifications.nl">
              {{ page.user.notifications.nl }}
            </div>
          </v-row>
        </v-col>

        <!-- Button Side -->
        <v-col style="text-align: center">
          <div>
            <v-btn
              class="grey4 text--text"
              style="margin-bottom: 30px; width: 300px"
            >
              Admin'Modus
            </v-btn>
          </div>
          <div>
            <v-btn
              class="grey4 text--text"
              style="margin-bottom: 30px; width: 300px"
            >
              Passwort Zurücksetzen
            </v-btn>
          </div>

          <div style="margin-bottom: 20px">
            <v-btn class="error" style="width: 300px">
              Konto löschen
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="requestData">
      <v-card width="100%" class="unvergessen-shadow">
        <!-- Name -->
        <div style="padding: 20px; text-align: center" class="grey3 title">
          <div style="position: relative">
            <div v-if="requestData.product.pageSkeleton">
              {{requestData.product.pageSkeleton.first_name + " " + requestData.product.pageSkeleton.last_name}}
            </div>
            <div style="position: absolute; right: 0; top: 0" @click="goToPost()">
              <v-btn>
                Besucheransicht
              </v-btn>
            </div>
          </div>
        </div>
        <!-- Content -->
        <div>
          <v-container class="py-0">
            <v-row>
              <!-- Basic Info -->
              <v-col cols="6" class="user-col">
                <div class="ma-3">
                  <v-row>
                    <unvergessenPageInfo
                    :data = 'requestData'></unvergessenPageInfo>
                  </v-row>
                  <v-row class="ma-0 pa-0">
                    <v-col>
                      <v-row>
                        <u>Seite inkl. Cache löschen</u>
                      </v-row>
                      <v-row>
                        <v-btn
                          class="grey2 text--text"
                          style="margin-bottom: 30px; width: 300px"
                        >
                          Admin-Modus
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <!-- Payment Info -->
              <v-col cols="6" class="user-col">
                <div class="pa-5">
                  <v-col v-if="requestData.payment">
                    <v-row>
                      <unvergessenPayInfo
                      class="pa-3"
                      :data = 'requestData'
                      :externalPayId = 'requestData.payment.externalPaymentID'
                      ></unvergessenPayInfo>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                      <v-btn
                        class="grey2 text--text"
                        style="margin-bottom: 30px; width: 300px"
                        @click="goToPay()"
                      >
                        Zahlungseinstellungen
                      </v-btn>
                    </v-row>
                  </v-col>
                  <v-row class="pa-0 ma-0" v-else>
                    <h3 class="pa-0 ma-0">Keine Zahlungsinformationen vorhanden</h3>
                  </v-row>
                </div>
              </v-col>
              <!-- Events -->
              <v-col cols="6" class="user-col">
                <div class="ma-3">
                  <v-row class="pa-0 ma-0">
                    <h3 class="pa-0 ma-0">Vergangene Events</h3>
                  </v-row>
                </div>
              </v-col>
              <!-- Notification Events -->
              <v-col cols="6" class="user-col">
                <div class="ma-3">
                  <v-row class="pa-0 ma-0">
                    <h3 class="pa-0 ma-2">Zukünftige Events</h3>
                  </v-row>
                  <v-row class="pa-0 ma-0">
                    <v-col class="pa-0 ma-2">
                      <v-row v-for="(event, i) in events" :key="i" class="pa-0 ma-0">
                        <v-col class="pa-0 ma-0">
                          {{new Date(event.dueDate).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'})}}
                        </v-col>
                        <v-col class="pa-0 ma-0">
                          GS{{' ' + event.mail + ' '}}Mail
                        </v-col>
                        <v-col class="pa-0 ma-0">
                          <v-icon color="success" v-if="event.processed && event.active">
                            mdi-check
                          </v-icon>
                          <v-icon v-if="event.processed && !event.active">
                            mdi-close
                          </v-icon>
                          <v-icon color="error" v-if="!event.processed && event.active" @click="openDialog(event, i, false)">
                            mdi-close
                          </v-icon>
                          <span v-if="!event.processed && !event.active" @click="openDialog(event, i, true)" style="cursor: pointer">
                            <u style="color: #febf7c">Reaktivieren</u>
                          </span>
                          <div v-if="!event.processed && event.active && event.mail === 'e3'" v-intersect='activeE3Btn'></div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="ma-0 pa-0">
                    <span style="cursor: pointer" v-if="e3btn" @click="openDialog(undefined, undefined, false)">
                      <u>Alle Mails außer E3 löschen</u>
                    </span>
                    <span v-else>
                      <u>Alle Mails außer E3 löschen</u>
                    </span>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card>

      <v-card width="100%" class="unvergessen-shadow my-4">
        <!-- title -->
        <div style="padding: 20px; text-align: center" class="grey3 title">
          Notizen
        </div>

        <!-- Content -->
        <div>
          <v-container class="py-0">
            <v-row>
              <v-col cols="6" class="user-col">
                <v-textarea outlined class="py-0" v-model="editedNote" @input="isEditable()"></v-textarea>
                <div style="text-align: right">
                  <v-btn class="success" @click="editNote()" :disabled='editable'>
                    Speichern
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="6" class="user-col">
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card>
    </v-container>
    <!-- Succes Dialog -->
    <unvergessen-success-dialog
    :dialog="succesAlert"
    :buttonText="'Dialog schließen'"
    v-on:close="succesAlert = false">
      Die Anfrage ist erfolgreich
    </unvergessen-success-dialog>
    <v-dialog v-model="confirmDialog" max-width="400px" @click:outside="confirmDialog = false; errorAlert= false">
      <v-card class="pa-3">
        <v-alert
          type="error"
          v-model="errorAlert"
        >Error</v-alert>
        <div style="position: relative">
          {{dialogText}}
        </div>
        <v-row>
          <v-col>
            <v-btn @click="confirmDialog = false; errorAlert= false">
              Abbrechen
            </v-btn>
          </v-col>
          <v-col>
            <v-btn :color="buttonStatus" @click="desactiveEvent()">
              {{buttonText}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import PayInfo from '@/components/finance/accounting/PayInfo.vue'
import PageInfo from '@/components/finance/accounting/PageInfo.vue'
import SuccessDialog from '@/components/global/SuccessDialog.vue'
export default {
  data () {
    return {
      urlPage: process.env.VUE_APP_SPA_LINK + '/Gedenkseite/',
      page: {},
      requestData: {
        product: {}
      },
      typeName: '',
      noteText: '',
      editedNote: '',
      editable: true,
      events: [],
      e3btn: false,
      // dialog data
      confirmDialog: false,
      dialogText: '',
      buttonText: '',
      event: {},
      status: false,
      succesAlert: false,
      errorAlert: false,
      currentIndex: 0,
      buttonStatus: 'error'
    }
  },
  methods: {
    mapVerifiedStatus () {
      switch (this.page.page.verified.status) {
        case false:
          return 'Nein'
        case true:
          return 'Ja'
        default:
          return 'Error'
      }
    },
    goToPost () {
      window.open(this.urlPage + this.page.page.pageSkeleton.page_id)
    },
    goToPay () {
      const route = this.$router.resolve({ name: 'AccountingUser', params: { uid: this.page.payment._id } })
      window.open(route.href)
    },
    openDialog (event, index, status) {
      this.confirmDialog = true
      this.event = event
      this.status = status
      if (event) {
        this.currentIndex = index
        if (status) {
          this.buttonText = 'Reaktivieren'
          this.dialogText = 'Möchtest du das Event reaktivieren?'
          this.buttonStatus = 'success'
        } else {
          this.buttonText = 'Event Löschen'
          this.dialogText = 'Möchtest du das Event wirklich löschen?'
          this.buttonStatus = 'error'
        }
      } else {
        this.buttonText = 'Alle Events Löschen'
        this.dialogText = 'Möchtest du alle Events außer E3 wirklich löschen?'
      }
    },
    activeE3Btn () {
      this.e3btn = true
    },
    desactiveEvent () {
      if (this.event) {
        axios
          .patch('/crm/page/page/notification', { id: this.event._id, page_id: this.uid, status: this.status })
          .then((res) => {
            this.events[this.currentIndex] = res.data[0]
            this.succesAlert = true
            this.confirmDialog = false
          })
          .catch(() => {
            this.errorAlert = true
          })
      } else {
        axios
          .patch('/crm/page/page/deactivate', { pid: this.uid })
          .then((res) => {
            this.events = res.data
            this.succesAlert = true
            this.confirmDialog = false
          })
          .catch(() => {
            this.errorAlert = true
          })
      }
    },
    editNote () {
      axios.patch('/crm/page/page/notes', {
        page_id: this.uid,
        notes: this.editedNote
      })
        .then((res) => {
          this.noteText = this.editedNote
          this.editable = true
        })
        .catch()
    },
    isEditable () {
      if (this.noteText === this.editedNote) {
        this.editable = true
      } else {
        this.editable = false
      }
    },
    deleteEvent (item) {
      // TODO
      console.log('deleteEvent: ' + item)
    },
    openInvoice (item) {
      // TODO
      console.log('openInvoice: ' + item)
    },
    loadMore () {
      // TODO
    }
  },
  computed: {
    uid () {
      return this.$route.params.uid
    }
  },
  created () {
    axios
      .get('/crm/page/page', { params: { page_id: this.uid } })
      .then((res) => {
        this.page = res.data
        this.requestData.product = res.data.page
        this.requestData.payment = res.data.payment
        this.requestData.abo = res.data.abo
        this.noteText = res.data.page.notes.content
        this.editedNote = this.noteText
      })
      .catch(() => {})
    axios
      .get('/crm/page/page/notifications', { params: { page_id: this.uid } })
      .then((res) => {
        this.events = res.data
      })
      .catch(() => {})
  },
  components: {
    unvergessenPayInfo: PayInfo,
    unvergessenPageInfo: PageInfo,
    unvergessenSuccessDialog: SuccessDialog
  }
}
</script>

<style>
.user-col {
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc
}
</style>
